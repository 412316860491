(function(){
  'use strict';

  var alive5_mode = '{{ALIVE5_MODE}}';
  var widgetFiles = ['widget-min.js?s={{BUILD_TIMESTAMP}}'];

  /**
   * Load unbundled files 
   * in development mode
  */
  if(alive5_mode === 'dev'){
    widgetFiles = [
      'config.js',
      'helpers.js',
      'widget.js',
      'builder.js',
      'popup.js',
    ];
  }

  /**
   * @function
   * @description Vanilla replacement of jQuery`s `getScript` fn
   * @param {string} source
   * @param {function} callback
   * @example
   * 
   * A5.getScript('/myscript.js', function(){
   *      console.log(window.testValFromMyScript); //'working'
   * });
  */
  function getScript(source, callback) {
    var script = document.createElement('script');
    var prior = document.getElementsByTagName('script')[0];
    script.async = 1;

    script.onload = script.onreadystatechange = function(_, isAbort ) {
        if(isAbort || !script.readyState || /loaded|complete/.test(script.readyState) ) {
            script.onload = script.onreadystatechange = null;
            script = undefined;

            if(!isAbort && callback) {
                setTimeout(callback, 0);
            }
        }
    };

    script.src = source;
    prior.parentNode.insertBefore(script, prior);
  }
  

  /**
   * @function loadFiles
   * @description load all javascript files
   * @param {string} loadID - debug param, to see where this fn is called from (probably need to get rid of it)
  */
  function loadFiles(loadID){
    var i=0;

    function _load(file){
      var src = '{{RESOURCE_URL}}/js/' + file;

      getScript(src, function(){
        i++;
        var nextFile = widgetFiles[i];

        if(nextFile){
          _load(nextFile);
        } else {
          console.log('Widget module loaded ' + loadID + ' ' + alive5_mode);
        }
      });
    }

    _load(widgetFiles[i]);
  }


  /**
   * @function handleFBLoadSuccess
   * @description handle successfull ajax call to the fb page
   * @param {string} rawResponse
  */
  function handleFBLoadSuccess(rawResponse) {
    try {
      var response = JSON.parse(rawResponse);
      if (response.valid) {
        var fbStatusIframeHTML = '<iframe style="display:none;" src="{{RESOURCE_URL}}/fbstatus.html" />';

        A5.insertAdjacentHTML(document.body, A5.insertType.beforeend, fbStatusIframeHTML);
        
        window.addEventListener("message", (response) => {
          if(response.data.a5fb) {
            if (response.data.a5fb.status !== 'unknown') {
              getScript('{{RESOURCE_URL}}/js/fb.js', function(){
                console.log("fb module loaded");
              });
            } else {
              loadFiles('c');
            }
          }
        } , false);
      } else {
        loadFiles('b');
      }  
    } catch (error) {
      console.error(error);  
    }
  }


  /**
   * @function handleFBLoadError
   * @description handle failed ajax call to the fb page
   * @param {string} rawResponse
  */
  function handleFBLoadError(rawResponse) {
    try {
      var response = JSON.parse(rawResponse);
      console.error(response);
    } catch (error) {
      console.error(error);  
    }
  }

  
  /**
   * @function loadFB
   * @description make ajax call to the fb page
   * @param {string} rawResponse
  */
  function loadFB(fb_page_id,org_name) {
    var BACKEND_URL_V2 = '{{BACKEND_URL_V2}}';
    var fbPath = BACKEND_URL_V2 + '/admin/valid-facebook-page?fbPageId=' + fb_page_id + '&org_name=' + org_name;
    var options = {
      path: fbPath,
      method: 'GET',
      success: handleFBLoadSuccess,
      error: handleFBLoadError,
    };
    
    A5.ajax(options);
  }


  /**
   * @function init
   * @description Check logic and initialize widget load
  */
  function init() {
    var widgetElement = document.getElementById('a5widget') || 
                        document.createElement('div'); // fallback to the blank `div`
    var fbPageIdAttr = widgetElement.getAttribute('data-page_id');
    var orgNameAttr = widgetElement.getAttribute('data-alive5_org');
    var fbPageId = fbPageIdAttr || undefined;
    var orgName = orgNameAttr || undefined;
    var shouldloadFB = fbPageId && orgName && typeof window.orientation === 'undefined';

    if(shouldloadFB) {
      getScript('{{RESOURCE_URL}}/js/helpers.js', function(){
        loadFB(fbPageId,orgName);
      });
    } else {
      loadFiles('a');
    }
  }

  /** @todo validate this check */
  //  if(!(typeof window.orientation !== 'undefined')) {
    init();
  //  }
}());
